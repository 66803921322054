<template>
  <div class="activecode-search">
    正在开发中,敬请期待!
  </div>
</template>

<script>
export default {
  data(){
    return {

    }
  }
}
</script>

<style lang="less" scoped>
  .activecode-search{
    padding: 12px;
  }
</style>